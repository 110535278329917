import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Generic.jsx";
import Downloads from "../components/_ui/Downloads.jsx";
import ContactCallout from "../components/callouts/ContactCallout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Support`}</h1>
    <p>{`Please click on the images below to zoom in and read our Assembly Instructions, or alternatively download a copy on the left to print out. If you need any other information please don't hesitate to get in touch with us via the CONTACT page. Many thanks`}</p>
    <div className="breathe--small">
  <a href="/img/support/ASSEMBLYguide-p1_1000_707.jpg" title="View assembly instructions page 1" target="_blank">
    <img src="/img/support/assembly-instructions1-th_296_195_c1_center_center.jpg" alt="The first page of a set of instructions for assembling a pergola" />
  </a>
  <a href="/img/support/ASSEMBLYguide-p2_1000_707.jpg" title="View assembly instructions page 2" target="_blank">
    <img src="/img/support/assembly-instructions2-th_296_195_c1_center_center.jpg" alt="The second page of a set of instructions for assembling a pergola" />
  </a>
    </div>
    <h2>{`Downloads`}</h2>
    <Downloads files={[{
      filename: "Pergola assembly guide",
      path: "/downloads/ASSEMBLY.pdf"
    }]} mdxType="Downloads" />
    <section className="breakout wrapper-top--default">
  <div className="container">
    <ContactCallout mdxType="ContactCallout" />
  </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      